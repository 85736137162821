



.result1-article{

 width: 720px;
 height: 270px; 
 box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35);
 background-color: white;
 margin-top: 90px;   
 margin-left: 450px; 


}

.result1-image{
    width: 120px;
    height: 120px;
    border-radius: 25px;
  
    position: relative;
    left: 35px;
    top: 25px;
  }

  .shutterstock_1154073754 {
    width: 120px;
    height: 120px;
    border-radius: 25px;
    position: absolute;
    object-fit: cover;
  }

  .cat-title{
    position: relative;
    left: 150px;
    top:10px;
    width: 450px;
    height: 90px;
    margin-bottom: 10px;
    
}

.The-Big-Chill-Cakery {
    position: absolute;
    width: 450px;
    height: 90px;
    font-family: Poppins;
    font-size: 32px;
    font-weight: 900;
    color: #192f60;
   
}

   .location1{
    position: absolute;
    top: 52px;
    left: 5px;
    font-family: Poppins;
    font-size: 17px;
    font-weight: 605;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: left;
    color: #192f60;
}

.Address1{
    position: absolute;
    top: 84px;
    left: 5px;
    width: 420px;
    height: 23px;
    font-family: Poppins;
    color: #636f88;
    
}

.title-text
{
    position: absolute;
    color: #192f60;
    font-weight: bolder;
    font-size: 40px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    left: 80px;
    top: 85px;
    
}

/* Filter Box */


.filters-article{
    
    position: absolute;
    width: 270px;
    height: 670px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35);
    background-color: white;
    top:170px;
    left: 90px;
}

.Filters {
    position: absolute;
    width: 55px;
    height: 25px;
    margin: 0 154px 10px 0;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 990;
    font-stretch: extra-expanded;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #192f60;
    left:26px;
    top:17px;
  }

  .Select-Location-label {
    position: absolute;
    width: 106px;
    height: 20px;
    margin: 10px 102px 11px 1px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #192f60;
    left: 27px;
    top: 52px;
  }

  .Select_Location{
    position: absolute;
    width: 208px;
    height: 35px;
    border-radius: 3px;
    border: solid 1px #bec8dd;
    top:95px;
    left: 27px;
  }

  #Select_Location_box{
    padding-left: 15px;
    width: 208px;
    height: 35px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #8c96ab;
  
   }

.cuisine_type{
    position: absolute;
    width: 100px;
    height: 100px;
    left: 27px;
    top:143px;
}

.cuisine_type #cuisine_type_text{
    position: absolute;
    width: 106px;
    height: 20px;
    margin: 10px 102px 11px 1px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #192f60;
}

.cuisine_type_checkbox{
    position: absolute;
    width: 170px;
    height: 120px;
    top:44px;
    font-family: Poppins;
    font-size: 15px;
    color: #8c96ab;
}

.cuisine_type_checkbox > input{
    width: 22px;
    height: 22px;
    vertical-align: -5px;
    margin: 5px;
 }

 .cuisine_filter_cost{
    position: absolute;
    width: 230px;
    height: 170px;
    left: 27px;
    top: 363px;

}

#cuisine_filter_cost_text{
    position: absolute;
    width: 106px;
    height: 20px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 550;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #192f60;
}

.cuisine_type_radio{
    position: absolute;
    width: 200px;
    height: 250px;
    top:35px;
    font-family: Poppins;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #8c96ab;
}

.cuisine_type_radio > input{
  width: 18px;
  height: 18px;
  margin: 5px;
  border-radius: 9px;
  border: solid 1.5px #3a5075;
  vertical-align: -13%;

}

.cuisine_filter_sort{
    position: absolute;
    width: 230px;
    height: 120px;
    left: 27px;
    top: 550px;
    
}

.Sort{
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.8;
    letter-spacing: normal;
    text-align: left;
    color: #192f60;
    
   
  }





/* Pagination */


.pagination {
    
    position: absolute;
    left: 200px;
    width: 500px;
    margin-top: 30px;
    margin-left: 400px;
    padding-bottom: 20px;
    top: 800px;
}


.paginationdiv{
    
    margin-left: 25px;
    width: 36px;
    height: 36px;
    background-color: white;
    padding-left: 11px;
    padding-top: -1px;
    text-decoration: none;
    border-radius: 7px;
    border: solid 1px #8c96ab;

}

.pageNum{
    text-decoration: none;
    
    
}

.pagnumText{
    font-size: 22px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #8c96ab;
}

.page0{
    position: absolute;
    width: 15px;
    height: 15px;
    margin: 0 13px 0 0;
    padding-right: 12px;
    padding-left: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 7px;
    border: solid 1px #8c96ab;
    background-color: #fff;
    left:40px;
    text-align: center;
}

.page1{
    position: absolute;
    width: 15px;
    height: 15px;
    margin: 0 13px 0 0;
    padding-right: 8px;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 7px;
    border: solid 1px #8c96ab;
    background-color: #8c96ab;
    left:95px;
}



.pagination > div > a  {

    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    padding-right: -0.9px;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.7;
    letter-spacing: normal;
    text-align: center;
    color: #8c96ab;
}

.selected {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    padding-right: -0.9px;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.7;
    letter-spacing: normal;
    text-align: center;
    color:#fff;
}

.pagination > div:hover {
    background-color: #e5e9f1;
}




.cuisine-cost{
    
    width: 200px;
    height: 78px;
    margin-left: 8px;
    margin-top: 7px;
    padding-top: 10px;
    padding-left: 50px;
   
}


  .CUISINES-COST-FOR-TWO{
 
    padding-left: 0px;
    width: 120px;
    height: 118px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: left;
    color: #636f88;
  }

  

  .Path-6195 {
    width: 650px;
    height: 0;
    margin-left: 22px;
    margin-top: 55px;
    border: solid 1px #dedfe5;
  }