*{
  box-sizing: border-box;
  font-family: Poppins;
}

body{
  background-color: white;
  overscroll-behavior: contain;
  
}

/* * {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
} */

@media screen and (max-width:425px) {

  .icon {
    background: url("https://static.thenounproject.com/png/101791-200.png") no-repeat left 50% ;
    background-size: 20px;
    font-family: Poppins;
    width: 370px;
    height: 60px;
    color: #0e57dd;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    background-color: #fff;
    background-position: right 95% bottom 45%;
    position: relative;
    padding-left: 50px;
    
  }
  
  .registerMessageStyle{
    margin-left: 50px;
    margin-top: 10px;
  }
  
    .CopyrightStyleHome{
    display: inline-block;
    position: absolute;
    top: 244vh;
    left: 11px;
    padding-bottom: 10px;
    font-size: 16px;
  }

  .qs_menu2{
    max-width: 100%;
    padding-left: 40px;
    padding-right: 60px;
    padding-bottom: 50px;
  }

  .back-img{
    background-image: url('../Assets/Images/shutterstock_348320018@3x.jpg');
    height: 480px;
    background-size: cover;
  }
  
  .login, .createacc, .login:hover, .createacc:hover{
    color: white;
    text-decoration: none;
    opacity: 0.8;
  }
  
  .logo{
    display: inline;
    color: #eb2929;
    border-radius: 50%;
    font-size: 70px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.9;
    letter-spacing: normal;
    background-color: white;
    margin-left: 52px;
  }
  
  .restaurant-title{
    font-size: 36px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    color: #fff;
  }
  
  
  .locationDropdown{
    appearance: none;
    border: none;
    font-family: Poppins;
    color: #1f3d75;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    background-color: #fff;
  }
  
  
  ::placeholder {
    color: #1f3d75;
    opacity: 1;
  }
  
  .Quick-Searches {
    width: 390px;
    height: 75px;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 700;
    color: #192f60;
  }
  
  .Discover-restaurants-by-type-of-meal{
    width: 340px;
    height: 25px;
    font-family: Poppins;
    font-size: 18px;
    color: #8c96ab;
    margin-left: 27px;
    margin-top: -27px;
  }
  
  .qk1_heading{
    width: 88px;
    height: 25px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #192f60;   
  }
  
  .qk1_info{
    width: 137px;
    height: 62px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #8c96ab;
  }
  

/* ------------------------------------------------ */
/* RegisterPage Css */
/* ------------------------------------------------ */


.registermodal{
  position: absolute;
  width: 100vw;
  height: 300px;
    top: 200px;
    left: 1px;
    right: 40px;
    bottom: 40px;
    padding-top: 10px;
    background-color: papayawhip;
    height: max-content;
    padding-bottom: 10px;
}

.registermodaloverlay{
  position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(136, 136, 136, 0.65);
    z-index: 21;  
}

.grid-container-register{
  width: 410px;
  display: grid;
  grid-template-columns: repeat(2, 125px);
  grid-auto-rows: minmax(10px, auto);
  padding-left: 30px;
  margin-top: 30px;

}
  
.btnRegisterGoBack{
  margin-top: 25px;
  margin-left: 145px;
 }

.grid-container-register > input{
  width: 210px;

}

.grid-item-sub{
  grid-column: 1 / -1;
  width: 420px;
  margin-top: 10px;
  margin-left: 60px;
}  
  
  /*  Dropdown css for small mobile device*/
  .RestSearch{
    
    width: 400px;
    height: 168px;
    padding-top: 20px;
    margin-top: -20px;
   }

   .RestSearchDropDown{
    position: relative;
    width: 210px;
    height: 60px;
    margin-left: 75px;
    opacity: 90%;
    background-color: red;
   }

   .RestlocationDropdown{
    position: relative;
    height: 60px;
    padding-left: 10px;
    appearance: none;
    border: none;
    font-family: Poppins;
    color: #1f3d75;
    font-size: 15px;
    font-weight: bolder;
    padding-left: 20px;
    max-width: fit-content;
   }

   .RestlocationDropdown > option{
     max-width: fit-content;
   }


  /*   SearchBar css for small mobile*/
   .SearchInputMain{
      position: relative;
      width: 370px;
      height: 229px;
      margin-top: 15px;
      margin-left: 12px;
   }



  .RestSearchDetails{
   
    background-color: white;

  }


  .RestaurantListCSS1 > ul{

    list-style-type: none;
    padding-left: 10px;
    background-color: #fff;
    text-indent: 20px; 
    margin-bottom: 2px;
    border: solid black;
    position: relative;
    z-index: 9;
    
  }

  .RestaurantListCSS1 > ul > li {
    margin-left: -10px;
    padding-top: 5px;
    
  }

  .RestaurantListCSS1 > ul > li:hover{
    background: rgb(149, 207, 240);
    width: 368px;
  }


  .location-selector-div{
      width:432px; 
      height: 80px;
      margin-left: 14px;
      
      
  }


  .locationDropdown{
      position: absolute;
      width:392px; 
      height: 60px;
      right: 58px;
      top: 440px;
  }


  .restaurantSelector{
 
    width: 398px;
    height: 60px;
    margin-left:18px;
    margin-top: -37px;
    
  }

  .Search_More{
    position: relative;
    margin-left: 80px;
    margin-top: 29px;
    margin-bottom: 10px;
    width: 310px;
  }

  .Search_MoreText{
    font-size: 14px;
  }
  
  .back-img {
  background-image: url('../Assets/Images/shutterstock_348320018@3x.jpg');
  width:420;
  height: 580px;
  background-size: cover;
  }

  

  .qs_div{
      width: 360px;
      height: 160px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      margin-left: -18px;
    }

    .Quick-Searches{
        margin-left: -70px;
    }

    

    .logoutDiv{
      display: inline-block;
      width: 100px;
      margin-left: 10px;
      
    }

    .login{
      position: absolute;
      display: inline-block;
      font-size: 16px;
      font-weight: bolder;
      color: white;
      top: 33px;
      left: 12px;

    }

    .login2{
      position: absolute;
      display: inline-block;
      font-size: 15px;
      color: white;
      left: -20px;
      top: 5px;
      border: solid whitesmoke;
      padding: 10px;

    }

      .createacc{
      margin-left: 60px;
      width: max-content;
    }

    .logoutLabel{
        
      margin-left: 60px;
      width: max-content;

    }
}

@media screen and (min-width:451px) {
  
  .registerMessageStyle{
    margin-left: 30px;
    margin-top: 10px;
    position: absolute;
  }
  
  
  .qs_menu2{
    max-width: 100%;
    padding-left: 89px;
    padding-right: 60px;
    padding-bottom: 50px;
  }

  .back-img{
    background-image: url('../Assets/Images/shutterstock_348320018@3x.jpg');
    height: 480px;
    background-size: cover;
  }
  
  .login, .createacc, .login:hover, .createacc:hover{
    color: white;
    text-decoration: none;
    opacity: 0.8;
  }
  
  .logo{
    display: inline;
    color: #eb2929;
    border-radius: 50%;
    font-size: 70px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.9;
    letter-spacing: normal;
    background-color: white;
  }
  
  .restaurant-title{
    font-size: 36px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    color: #fff;
  }
  
  
  .locationDropdown{
    appearance: none;
    border: none;
    font-family: Poppins;
    color: #1f3d75;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    background-color: #fff;
  }
  
  
  ::placeholder {
    color: #1f3d75;
    opacity: 1;
  }

/* ------------------------------------------------ */
/* RegisterPage Css */
/* ------------------------------------------------ */


.registermodal{
    position: absolute;
    width: 600px;
    height: 500px;
    top: 90px;
    left: 350px;
    right: 40px;
    bottom: 40px;
    padding: 50px;
    background-color: papayawhip;
}

.registermodaloverlay{
  position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(136, 136, 136, 0.65)
}

.grid-container-register{

  margin: 10px 20px 50px 40px;
  display: grid;
  grid-template-columns: repeat(2, 150px);
  grid-auto-rows: minmax(10px, auto);
  
}
  
.btnRegisterGoBack{
  
  width: 210px;
  margin-top: -72px;
  margin-left: 40px;
 }
  
.grid-container-register > input{
  width: 210px;
  margin-bottom: 10px;
}

.grid-item-sub{
  grid-column: 1 / -1;
  width: 420px;
}
 
  /*  Search Dropdown css for large*/

   .RestSearch{
    
    width: 405px;
    height: 100px;
    padding-top: 20px;
   }

   .RestSearchDropDown{
    position: relative;
    width: 245px;
    height: 60px;
    
    margin-left: 410px;
    
   }

   .RestlocationDropdown{
    position: relative;
    width: 245px;
    height: 60px;
    padding-left: 30px;
    appearance: none;
    border: none;
    font-family: Poppins;
    color: #1f3d75;
    font-size: 15px;
    font-weight: bolder;
    
   }

  /*  SearchBar css for large*/
   .SearchInputMain{
      position: absolute;
      width: 400px;
      height: 229px;
      margin-top: -60px;
      margin-left: 670px;
      
   }

   .icon {
    background: url("https://static.thenounproject.com/png/101791-200.png") no-repeat left 50% ;
    background-size: 20px;
    font-family: Poppins;
    width: 400px;
    height: 60px;
    color: #0e57dd;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    background-color: #fff;
    background-position: right 95% bottom 45%;
    position: relative;
    padding-left: 50px; 
    
    
  }

  .RestSearchDetails{
    background-color: white;
    
    
  }

  .RestaurantListCSS1 > ul{

    list-style-type: none;
    padding-left: 10px;
    background-color: #fff;
    text-indent: 20px; 
    margin-top: 5px;
    appearance: none;
    border: none;
    font-family: Poppins;
    color: #1f3d75;
    font-size: 15px;
    font-weight: bolder;
    margin-bottom: 5px;
    border: solid black;
    margin-top: 0px;
    position: relative;
    z-index: 4;
  }

  .RestaurantListCSS1 > ul > li {
    margin-left: -10px;
    padding-top: 5px;
    
  }

  .RestaurantListCSS1 > ul > li:hover{
    background: rgb(149, 207, 240);
    width: 393px;
    
  }




  .location-selector-div{
      width: 145px;
      height: 90px;
      margin-left: -80px;

      
  }

  

  .restaurantSelector{

    width: 400px;
    margin-left: 10px;
  }

  .Search_More{
    position: absolute;
    margin-left: 840px;
    top: 518px;
    
  }

  .login{
    font-size: 20px;
  }

  .createacc{
    margin-left: 20px;
  }
  

  
  .logoutDiv{
    display: inline-block;
    width: 250px;
    margin-left: 15px;
  }

  .locationDropdown{
      width: 245px;
      height: 60px;
      margin-left: -13px;
     
  }


  .Quick-Searches {
    width: 490px;
    height: 75px;
    font-family: Poppins;
    font-size: 50px;
    font-weight: 700;
    color: #192f60;
    margin-left: 10px;
    margin-bottom: -20px;
  }
  
  .Discover-restaurants-by-type-of-meal{
    width: 490px;
    height: 25px;
    font-family: Poppins;
    font-size: 18px;
    color: #8c96ab;
    margin-left: 108px;
    margin-top: 30px;
    margin-bottom: 5px;
  }
  
  .qk1_heading{
    width: 88px;
    height: 25px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #192f60;   
  }
  
  .qk1_info{
    width: 137px;
    height: 62px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #8c96ab;
  }
  
  .qs_div{
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }

  .createacc{
    margin-left: 100px;
    width: 146px;
  }

  .logoutLabel{
      
    margin-left: 60px;
    width: 95px;

  }

  .login2{
    position: absolute;
    display: inline-block;
    font-size: 15px;
    color: white;
    left: 12px;
    top: 5px;
    border: solid whitesmoke;
    padding: 10px;

  }
  
    .CopyrightStyleHome{
    display: inline-block;
    position: absolute;
    top: 995px;
    left: 500px;
    padding-bottom: 12px;
  }

  

}








