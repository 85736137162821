@media screen and (max-width:450px){
    
.CopyrightStyle{
        display: inline-block;
        position: absolute;
        top: 104vh;
        left: 14px;
        padding-top: 0px;
        font-size: 15px;
 }
    
.UserPaymentPageOverlay{
     
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(136, 136, 136, 0.65);
    
}
    
.UserPaymentPageModal{
     
         position: absolute;
         top:100px;
         bottom: 350px;
         right: 0;
         left: 0;
         margin-left: 20px;
         padding-top: 10px;
         padding-left: 10px;
         width: 90vw;
         height: 50vh;
         border: 2px solid black;
         background-color: white;
    
}

/* Modal for Cart details view (mobile screen)*/

.modalCart{
    position: fixed;
    top: 50px;
    bottom: 0;
    right: 0;
    left: 10px;
    padding-top: 10px;
    width: 100vw;
    height: 100vh;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35);
    overflow: hidden;
    padding-left: 0px;
}
    
.modalCartOverlay{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(136, 136, 136, 0.65);
}


.grid-container{
    display: grid;    
    font-size: 20px;
    font-weight: bolder;
    width: 93vw;
    background-color: white;    
    grid-auto-rows: minmax(10px,auto);
    grid-template-columns: repeat(2,150px);
    margin-left: 8px;
    overflow: hidden;
}
    
.CartPage{
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.grid-header{
     grid-column-start: 1;
     grid-column-end: 4;
     background-color: aqua;
        
}

.gridItemsCart{

    grid-column-start: 1;
    grid-column-end: 4;
    background-color: beige;
    margin-top: 10px;
    
}

.modalCartCartTotal{
    font-weight: bolder;
    padding-bottom: 15px;
    margin-top: 89px;
    margin-left: 90px;
    display: inline-block;
}

.modalCartPaynow{
    
    margin-bottom: 70px;
    margin-top: 10px;
    width: 150px;
    margin-left: 100px;
}

.modalCartPaynowspan{
    font-size: 15px;
    font-weight: bolder;
}

.modalCartPDFgen{
    width: 110px;
    margin-top: -350px;
}

.modalCartPrintdetailstext{

    font-weight: bolder;
    font-size: 13px;

}

.modalCartGoBackbtn{
    
    margin-bottom: -10px;
    margin-top: -90px;
    margin-left: 100px;
    width: 150px;
}


.modalCartItemName{
    margin-left: 5px;
    width: 160px;
    display: inline-block;
    font-size: 18px;
}

.modalCartItemsDetails{
    float: right;
    color: white;
    font-family: Calibri;
    margin-right: 2px;
}

.CartdetailsMainPricemenuitemscart{
    background-color: rgb(13, 2, 63);
    width: 40px;
    display: inline-block;
    text-align: center;

}

.Cartdetailsmenuitemscart{
    background-color: darkblue;
    width: 40px;
    display: inline-block;
    text-align: center;
}

.CartdetailsTOTmenuitemscart{
    background-color: darkblue;
    width: 40px;
    display: inline-block;
    text-align: center;
}

.RestaurantDetailImage{
    position: absolute;
    background: url('../Assets//Images/breakfast.jpg') no-repeat left 35%;
    background-size: 100%;
    top: 100px;
    height: 300px;
    width: 100vw;
    overflow: hidden;
}

.galleryButton{
    position: absolute;
    right: 0px;
    top: 90px;
    color: rgb(7, 7, 80);
    background-color: white;
    float: right;
    margin-right: 20px;
    margin-top: 100px;
    width: 250px;
    height: 60px;
    opacity: 75%;
    font-weight: bolder;
  
}

.RestaurantName{
    position: absolute;
    left: 10px;
    top: 465px;
}

.PlaceOrder{
    position: absolute;
    left: 3px;
    top: 390px;
    margin:17px;
    float:right;
    background-color: #ce0505;
}

.tabs{
    position: absolute;
    top: 520px;
    left: 15px;
}

.tabs__content{
    position: absolute;
    top: 47px;
    left: 10px;
}

.NameInModal{
  font-size: 30px;
  overflow: hidden;
  font-weight: bolder;
}

.ulModalMenu{
    padding-left: 8px;
    list-style-type: none;
}

.ModalItemName{
    font-size: 15px;
    font-weight: bold;
    margin-left: 0px;
    margin-right: 2px
}

.ModalItemPrice{
    font-size: 15px;
    font-weight: bold;
    margin-left: 0px;
}

.ModalItemDescription{
    font-size: 15px;
    font-weight: bold;
    margin-left: 0px;
    color: darkgreen;
}

.MenuButtonModal{
    position: absolute;
    width: 140px;
    right: 28px;
    margin-top: -58px;
    padding-left: 20px;

}

.minus{
    
    width: 30px;
    height: 30px;
    overflow: hidden;
    margin-right: 10px;

}

.minusSign{
    position: absolute;
    display: inline-block;
    margin-right: 10px;
    top: 5px;
    left: 31px;
}

.quantityMenuStyle{

    width: 30px;
    height: 30px;
    overflow: hidden;
    margin-right: 10px;

}

.quantityMenuStyleDisplay{

    position: absolute;
    display: inline-block;
    margin-right: 10px;
    top: 5px;
    left: 70px;

}

.plus{
    width: 30px;
    height: 30px;
    overflow: hidden;
    margin-right: 10px;
}

.plusSign{
    position: absolute;
    display: inline-block;
    margin-right: 10px;
    top: 5px;
    left: 109px;
}

.TotalPriceMenuDisplay{
    font-size: 20px;
    font-weight: bolder;
    display: inline;
}

.ConfirmButton{

    float: right;
    margin-top: -5px;
    margin-right: 5px;
}

.modalRestOverlay{
    background-color: red;
}

.modalRest{
   position: absolute;
   width: 370px;
   height: max-content;
   right: 10px;
   left: 18px;
   padding: 20px;
   top: 50px;
   bottom: 10px;
   background-color: white;
   box-shadow: 0 8px 6px 5px rgba(0, 0, 0, 0.16);
}

}


@media screen and (min-width:451px){
    
.CopyrightStyle{
    display: inline-block;
    position: absolute;
    top: 765px;
    left: 500px;
    padding-bottom: 10px;
  }
    
    
.UserPaymentPageOverlay{
     
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(136, 136, 136, 0.65);

}

.UserPaymentPageModal{
 
     position: absolute;
     top:60px;
     bottom: 110px;
     right: 0;
     left: 0;
     margin-left: 240px;
     padding-top: 12px;
     padding-left: 50px;
     width: 900px;
     border: 2px solid black;
     background-color: white;
     margin-bottom: 10px;

}
    
/* Modal for Cart details view (Large screen)*/

.modalCart{
    position: absolute;
    top: 30px;
    bottom: 0;
    right: 0;
    left: 200px;
    padding-top: 10px;
    width: 70vw;
    height: max-content;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35);
    overflow: hidden;
    padding-left: 0px;
}

.modalCartOverlay{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(136, 136, 136, 0.14);
}


.grid-container{
    display: grid;    
    font-size: 20px;
    font-weight: bolder;
    margin-left: 50px;
    background-color: #fff;
    width: 800px;
        
}

.grid-header{
     grid-column-start: 1;
     grid-column-end: 4;
        
}

.gridItemsCart{

    grid-column-start: 1;
    grid-column-end: 4;
    background-color: beige;
    color: black;

}

.modalCartCartTotal{
    font-weight: bolder;
    overflow: hidden;
    padding-bottom: 10px;
    margin-top: 30px;
    margin-left: 80px;
}

.modalCartPaynow{
    
    margin-bottom: 70px;
    margin-top: -1px;
    width: 450px;
    margin-left: 225px;
}

.modalCartPaynowspan{
    font-size: 30px;
    font-weight: bolder;
}


.modalCartPDFgen{
    width: 200px;
    margin-top: -360px;
    margin-left: 645px;
}

.modalCartPrintdetailstext{

    font-weight: bolder;
    font-size: 20px;

}

.modalCartGoBackbtn{
    margin-left: 340px;
    margin-top: -120px;
    width: 220px;
}


.modalCartItemName{
    margin-left: 70px;
    width: 240px;
    display: inline-block;
}

.modalCartItemsDetails{
    float: right;
    color: white;
    font-family: Calibri;
    margin-right: 80px;
}

.CartdetailsMainPricemenuitemscart{
    background-color: rgb(13, 2, 63);
    width: 40px;
    display: inline-block;
    text-align: center;

}

.Cartdetailsmenuitemscart{
    background-color: darkblue;
    width: 40px;
    display: inline-block;
    text-align: center;
}

.CartdetailsTOTmenuitemscart{
    background-color: darkblue;
    width: 90px;
    display: inline-block;
    text-align: center;
}    
    
.PlaceOrder{
    
    float:right;
    background-color: #ce0505;
    margin-top: -73px;
}
    
    
.modalRestOverlay{
     background-color: red;
}

.modalRest{
    position: absolute;
    width: 1200px;
    height: max-content;
    right: 10px;
    left: 70px;
    padding: 30px;
    top: 30px;
    bottom: 10px;
    background-color: white;
    box-shadow: 0 8px 6px 5px rgba(0, 0, 0, 0.16);
}

.MenuButtonModal{
    position: absolute;
    width: 300px;
    right: 45px;
    margin-top: -70px;
    padding-left: 10px;
    overflow: hidden;
}



.NameInModal{

    overflow: hidden;
    font-size: 40px;
    font-weight:bolder;

}

.ModalItemName{
    font-size: 25px;
    font-weight: bolder;
    
}

.ModalItemPrice{
    font-size: 25px;
    font-weight: bolder;
}

.ModalItemDescription{
    font-size: 18px;
    font-weight: bolder;
    color: darkgreen;
    
}

.ulModalMenu{
    padding-left: 65px;
}

.minus{
    
    width: 70px;
    height: 70px;
    overflow: hidden;
    margin-right: 10px;

}

.minusSign{
    position: absolute;
    display: inline-block;
    font-size: 45px;
    margin-right: 10px;
    top: 5px;
    left: 31px;
}

.quantityMenuStyle{

    width: 70px;
    height: 70px;
    overflow: hidden;
    margin-right: 10px;

}

.quantityMenuStyleDisplay{

    position: absolute;
    display: inline-block;
    font-size: 45px;
    margin-right: 10px;
    top: 5px;
    left: 110px;

}

.plus{
    width: 70px;
    height: 70px;
    overflow: hidden;
    margin-right: 10px;
}

.plusSign{
    position: absolute;
    font-size: 45px;
    display: inline-block;
    margin-right: 10px;
    top: 5px;
    left: 189px;
}

.TotalPriceMenuDisplay{
    overflow: hidden;
    font-size: 55px;
    margin-left: 60px;
}

.ConfirmButton{

    float: right;
    margin-top: -75px;
    margin-right: 80px;
    width: 250px;
    height: 70px;
}

.confirmbtnText{
    font-size: 25px;
    font-weight: bolder;
}

.clearItems{
    display: inline-block;
    position: absolute;
    left: 965px;
    top:90px;
}
    
.galleryButton{
    position: absolute;
    right: 0px;
    top: 90px;
    color: rgb(7, 7, 80);
    background-color: white;
    float: right;
    margin-right: 180px;
    margin-top: 210px;
    width: 250px;
    height: 60px;
    opacity: 75%;
    font-weight: bolder;
}
    
    
.RestaurantDetailImage{
    background: url('../Assets//Images/breakfast.jpg') no-repeat left 35%;
    background-size: 100%;
    margin-top: 50px;
    margin-left: 120px;
    margin-right: 120px;
    background-color: gold;
    height: 290px;
    
}
    
}
